import React from "react"
import { Link } from "react-router-dom"

const NotFound = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#fefefe',
    padding: '40px',
    textAlign: 'center',
    color: '#444'
  }

  const titleStyle = {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px'
  }

  const messageStyle = {
    fontSize: '1.2rem',
    color: '#555',
    marginBottom: '30px'
  }

  const buttonStyle = {
    backgroundColor: '#3b5998',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    fontSize: '1rem',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none'
  }

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Ups, noticia no encontrada</h1>
      <p style={messageStyle}>
        Parece que la noticia que buscas no está disponible en este momento.
        Por favor, intenta de nuevo más tarde o regresa al inicio para ver nuestras últimas noticias.
      </p>
      <Link to="/" style={buttonStyle}>
        Volver al inicio
      </Link>
    </div>
  )
}

export default NotFound;
