import React, { createContext, useEffect, useState } from "react";

// Creamos el contexto
export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Al montar el Context, hacemos fetch directamente
  useEffect(() => {
    fetchNewsFromServer();
  }, []);

  // Fetch real desde nuestro backend cacheado
  const fetchNewsFromServer = async () => {
    // Cambia la URL a la de tu backend (ej.: Koyeb o local)
    const baseURL = "https://miserable-gabbey-gerardojaviergarcia-4fe33381.koyeb.app/api/news";
    // const baseURL = "http://localhost:8000/api/news";

    try {
      setLoading(true);
      const res = await fetch(baseURL);
      const jsonData = await res.json();

      if (jsonData?.data) {
        setNewsData(jsonData.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };

  // Por si en algún momento quieres forzar un refresh manual 
  // (un botón "Refrescar" en tu UI, por ejemplo)
  const refreshNews = () => {
    fetchNewsFromServer();
  };

  // El value que compartimos vía contexto
  const value = {
    newsData,
    loading,
    refreshNews,
  };

  return (
    <NewsContext.Provider value={value}>
      {children}
    </NewsContext.Provider>
  );
};
