import React, { useContext } from "react"
import Slider from "react-slick"
import { NewsContext } from "../../../context/NewsContext" // Ajusta la ruta según tu estructura de carpetas
import "./style.css"
import LoadingSpinner from "../../common/LoadingSpinner";
const SinglePageSlider = () => {
  // Consumimos el contexto para obtener las noticias
  const { newsData, loading } = useContext(NewsContext)

  // Configuración del slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }

  // Mientras cargan las noticias, podrías mostrar algo
  if (loading) {
    return <LoadingSpinner />;
  }

  // Selecciona las noticias que quieras mostrar en este slider (por ejemplo, 8)
  const sliderItems = newsData.slice(0, 8)

  return (
    <section className='singlePopular'>
      <div className='content'>
        <Slider {...settings}>
          {sliderItems.map((val) => {
            return (
              <div className='items' key={val.id}>
                <div className='box'>
                  <div className='images'>
                    <img src={val.cover} alt='' />
                  </div>
                  <div className='text'>
                    <h1 className='title'>{val.title}</h1>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default SinglePageSlider
