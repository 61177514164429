import React, { useContext } from "react"
import { NewsContext } from "../../../context/NewsContext"  // Ajusta ruta
import "./hero.css"
import Card from "./Card"
import LoadingSpinner from "../../common/LoadingSpinner";

const Hero = () => {
  // Consumimos nuestro contexto
  const { newsData, loading } = useContext(NewsContext)

  // Mientras está 'loading', mostramos algo
  if (loading) {
    return <LoadingSpinner />;
  }

  // Tomamos las 4 primeras noticias
  const items = newsData.slice(0, 4)

  return (
    <section className='hero'>
      <div className='container'>
        {items.map((item) => (
          <Card key={item.id} item={item} />
        ))}
      </div>
    </section>
  )
}

export default Hero
