import React, { useState, useEffect, useContext } from "react"
import { format } from "date-fns"
import Head from "./Head"
import "./header.css"
import { Link, useHistory } from "react-router-dom"
import { WiThermometer } from 'react-icons/wi'
import { BsCalendar3 } from "react-icons/bs"
import { MdLocationOn } from "react-icons/md"
import { MdArrowBack } from 'react-icons/md'

// Importa tu NewsContext
import { NewsContext } from "../../../context/NewsContext"

const Header = () => {
  const [navbar, setNavbar] = useState(false)
  const [clima, setClima] = useState(null)

  const API_KEY = '7a18d636ea41da88fdb4adcdf660a30d'
  const CITY = 'Corrientes,AR'

  const history = useHistory()

  // Consumimos el contexto para acceder a las noticias
  const { newsData, loading } = useContext(NewsContext)

  useEffect(() => {
    const obtenerClima = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?q=${CITY}&appid=${API_KEY}&units=metric`
        )
        if (response.ok) {
          const data = await response.json()
          setClima(data)
        } else {
          throw new Error('No se pudo obtener el clima')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }

    obtenerClima()
  }, [])

  const currentDate = format(new Date(), "dd/MM/yyyy")

  // Seleccionamos, por ejemplo, 5 noticias para el marquee
  // Ajusta la cantidad y posición de slice según lo que desees
  const marqueeItems = newsData.slice(0, 5)

  return (
    <>
      <Head />
      <header>
        <div className='container paddingSmall'>
          <nav>
            <ul
              className={navbar ? "navbar" : "flex"}
              onClick={() => setNavbar(false)}
            >
              <img
                src="/images/logo.webp"
                alt="logo"
                style={{ height: "120px", width: "120px" }}
              />
              <h3 className="titulo-navbar">
                <Link to='/'>CORRIENTES A UN CLICK</Link>
              </h3>

              {/* Resto de las opciones del menú */}
              <li>
                <Link to='/'>Inicio</Link>
              </li>
              <li>
                <Link to='/cultura'>Cultura</Link>
              </li>
              <li>
                <Link to='/politica'>Politica</Link>
              </li>
              <li>
                <Link to='/deportes'>Deportes</Link>
              </li>
              <li>
                <Link to='/espectaculos'>Espectaculos</Link>
              </li>
              <li>
                <Link to='/mundo'>Mundo</Link>
              </li>
              {/* Muestra ubicación, fecha y temperatura */}
              <li className="location-info">
                <div className="flex">
                  <MdLocationOn /> Corrientes
                </div>
                <div className="flex">
                  <BsCalendar3 style={{ marginRight: '5px' }} />
                  {currentDate}
                </div>
                {clima ? (
                  <div className="flex">
                    <WiThermometer /> {clima.main.temp}°C
                  </div>
                ) : (
                  <div className="flex">Cargando...</div>
                )}
              </li>
            </ul>

            {/* MARQUEE con las primeras 5 noticias (o las que elijas) */}
            <marquee behavior="scroll" direction="left" style={{ padding: "5px" }}>
              {loading ? (
                <span style={{ color: "white" }}>Cargando noticias...</span>
              ) : (
                marqueeItems.map((val, index) => (
                  <Link
                    key={index}
                    to={`/SinglePage/${val.id}`}
                    style={{ marginRight: 20, fontSize: "15px" }}
                  >
                    <span style={{ marginTop: "20px", color: "white" }}>
                      <span style={{ color: "green", fontSize: "20px" }}>► </span>
                      {val.title}
                    </span>
                  </Link>
                ))
              )}
            </marquee>

            {/* Botón para volver atrás */}
            <button className='barIcon' onClick={() => history.goBack()}>
              <MdArrowBack />
            </button>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
