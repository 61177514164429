import React, { useContext } from "react"
import { useParams } from "react-router-dom"
import { NewsContext } from "../../context/NewsContext" // Ajusta la ruta
import Side from "../home/sideContent/side/Side"
import SinglePageSlider from "./slider/SinglePageSlider"
import "./singlepage.css"
import "../home/sideContent/side/side.css"
import "../home/mainContent/homes/style.css"
import NotFound from "./NotFound"; // ajusta la ruta según tu estructura
import LoadingSpinner from "../common/LoadingSpinner";

const SinglePage = () => {
  const { id } = useParams()
  const { newsData, loading } = useContext(NewsContext)

  if (loading) {
    return <LoadingSpinner />;
  }

  // Buscar la noticia cuyo 'id' coincida con la ruta
  const singleItem = newsData.find((item) => item.id === parseInt(id))

  if (!singleItem) {
    return <NotFound />;
  }
  
  

  // Funciones para compartir
  const shareUrl = window.location.href
  const title = singleItem.title

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(shareUrl)}`
    window.open(twitterUrl, "_blank")
  }

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`
    window.open(facebookUrl, "_blank")
  }

  return (
    <main>
      <SinglePageSlider />

      <div className="container">
        <section className="mainContent details">
          <h1 className="title">{singleItem.title}</h1>

          <div className="social">
            <div className="socBox">
              <i className="fab fa-facebook-f"></i>
              <button
                style={{ background: "#3b5998", fontWeight: "bold", fontSize: "1rem", border: "none", color: "white" }}
                onClick={shareOnFacebook}
              >
                COMPARTIR
              </button>
            </div>
            <div className="socBox">
              <i className="fab fa-x"></i>
              <button
                style={{ background: "black", fontWeight: "bold", fontSize: "1rem", border: "none", color: "white" }}
                onClick={shareOnTwitter}
              >
                COMPARTIR
              </button>
            </div>
          </div>

          {/* Descripción "desc" */}
          <div className="desctop">
            {singleItem.desc?.map((descItem, index) => (
              <p key={index}>{descItem.para1}</p>
            ))}
          </div>

          {/* Imagen principal */}
          <img src={singleItem.cover} alt="cover" />

          {/* Detalles "details" */}
          <div className="descbot">
            {singleItem.details?.map((detail, idx) => (
              <div key={idx}>
                {detail.title && <h2>{detail.title}</h2>}
                {detail.para1 && <p>{detail.para1}</p>}
              </div>
            ))}
          </div>

          <section className="Publicidad-Section">
            <img
              src="/images/portal/TODOS.jpeg"
              alt="Imagen de trabajo"
              className="Publicidad-Gobierno-Todos"
            />
          </section>
        </section>

        <section className="sideContent">
          <Side />
        </section>
      </div>
    </main>
  )
}

export default SinglePage
