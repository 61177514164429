import React, { useContext } from "react"
import { NewsContext } from "../../../../context/NewsContext"
import "./Popular.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Heading from "../../../common/heading/Heading"
import { Link } from "react-router-dom"
import LoadingSpinner from "../../../common/LoadingSpinner";
const Popular = () => {
  const { newsData, loading } = useContext(NewsContext)

  if (loading) {
    return <LoadingSpinner />;
  }

  // CONFIGURACIÓN DEL SLIDER
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 2,
    speed: 500,
    rows: 4,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 4,
        },
      },
    ],
  }

  // Asumiendo que tienes un total de 12 noticias en `newsData`,
  // las primeras 4 las muestra el Hero. Aquí tomarías las siguientes 8:
  const popularItems = newsData.slice(4, 12) // Del índice 4 al 11 (8 noticias)

  return (
    <>
      <section className='popular'>
        <Heading title='Noticias' />
        <section className="Publicidad-Section-Noticias">
          <img
            src="/images/portal/BANNER.jpeg"
            alt="Imagen de trabajo"
            className="publicidad-seccion-noticias"
          />
        </section>
        <div className='content'>
          <Slider {...settings}>
            {popularItems.map((val) => {
              return (
                <Link to={`/SinglePage/${val.id}`} key={val.id}>
                  <div className='items'>
                    <div className='box shadow'>
                      <div className='images row'>
                        <div className='img'>
                          <img src={val.cover} alt='' />
                        </div>
                        <div className='category category1'>
                          <span>{val.category}</span>
                        </div>
                      </div>
                      <div className='text row'>
                        <h1 className='title'>
                          {val.title.slice(0, 40)}...
                        </h1>
                        <div className='date'>
                          <i className='fas fa-calendar-days'></i>
                          <label>{val.time}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </Slider>
        </div>
      </section>
    </>
  )
}

export default Popular
